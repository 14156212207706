// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: '1.0.0',
 

    //  serverUrl1:'20.235.246.38:4000',
    // serverUrl:'http://20.235.246.38:4000/api/v1/'//dmgmori

    //   serverUrl1:'20.235.246.38:6001',
    // serverUrl:'http://20.235.246.38:6001/api/v1/'//dmgmori

    serverUrl1:'https://vewapi.yantra24x7.com',
    serverUrl:'https://vewapi.yantra24x7.com/api/v1/'

    //   serverUrl1:'https://trialapi.yantra24x7.com',
    // serverUrl:'https://trialapi.yantra24x7.com/api/v1/'

    // serverUrl1:'20.244.36.18:8001',
    // serverUrl:'http://20.244.36.18:8001/api/v1/'//dmgmori

    // serverUrl1:'4.224.82.109:6002',
    // serverUrl:'http://4.224.82.109:6002/api/v1/'//prackla testing ip

  //   serverUrl1:`${window.location.hostname}:7001`,
  // serverUrl:`${window.location.protocol + '//' + window.location.hostname}:7001/api/v1/`//dmgmori

      // serverUrl1:`${window.location.hostname}:6001`,
      // serverUrl:`${window.location.protocol + '//' + window.location.hostname}:6001/api/v1/`//dmgmori

      // serverUrl1:`${window.location.hostname}:8001`,
      // serverUrl:`${window.location.protocol + '//' + window.location.hostname}:8001/api/v1/`//dmgmori
     
      // serverUrl1:`${window.location.hostname}:${Number(window.location.port) - 3}`,
      // serverUrl: `${window.location.protocol}//${window.location.hostname}:${Number(window.location.port) - 3}/api/v1/` // Adjust as needed
};

