import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http:HttpClient) { }

  signin(login):Observable<any> {
      return this.http.post('login', login);
  }
  machine_count():Observable<any> {
    return this.http.get('machine_count')
  }

  true():Observable<any> {
    return this.http.get('check_status')
  }

//  export(){
//   return this.http.get('export')
//  }

  // forgot(params):Observable<any> {
  //   return this.http.get('sessions/forgot_pwd='+params)
  // }
  machinelist():Observable<any> {
    return this.http.get('hmi_macs');
}
idlereasonlist():Observable<any> {
  return this.http.get('hmi_idles');
}
macroentry(data):Observable<any> {
  return this.http.post('entry',data);
}

tabdata(machine):Observable<any> {
  return this.http.get('details?machine='+machine)
}

getsets(name){
  return this.http.get('mac_setting_list?L1Name='+name+'')
    }
}
