import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { AuthGuard} from '../app/Service/core/authentication/auth.guard';
import { AuthGuard} from '../app/Service/core/authentication/auths.guard';
    
   
const routes: Routes = [
  
{ path: '', loadChildren: () => import('./Components/login/login.module').then(m => m.LoginModule) },
{ path: 'm-dashboard', loadChildren: () => import('./Components/mdashboard/mdashboard.module').then(m => m.MdashboardModule) },
{ path: 'register', loadChildren: () => import('./Components/register/register.module').then(m => m.RegisterModule) },
{ path: 'sidebar', loadChildren: () => import('./Nav/sidebar/sidebar.module').then(m => m.SidebarModule) },
{ path: 'alarm', loadChildren: () => import('./Components/alarm/alarm.module').then(m => m.AlarmModule) },

{ path: 'dashboard', loadChildren: () => import('./Components/dashboard/dashboard.module').then(m => m.DashboardModule), },
{ path: 'report', loadChildren: () => import('./Components/report/report.module').then(m => m.ReportModule) },  { path: 'machine_registration', loadChildren: () => import('./Components/machine-registration/machine-registration.module').then(m => m.MachineRegistrationModule) },
{ path: 'user_management', loadChildren: () => import('./Components/user-management/user-management.module').then(m => m.UserManagementModule) },
{ path: 'operator_registration', loadChildren: () => import('./Components/operator-registration/operator-registration.module').then(m => m.OperatorRegistrationModule) },
{ path: 'shift', loadChildren: () => import('./Components/shift/shift.module').then(m => m.ShiftModule) },
{ path: 'trendchart', loadChildren: () => import('./Components/trendchart/trendchart.module').then(m => m.TrendchartModule) },
{ path: 'comparechart', loadChildren: () => import('./Components/comparechart/comparechart.module').then(m => m.ComparechartModule) },
{ path: 'Overallcomparechart', loadChildren: () => import('./Components/overallcomparechart/overallcomparechart.module').then(m => m.OverallComparechartModule) },
{ path: 'overallchart', loadChildren: () => import('./Components/overallchart/overallchart.module').then(m => m.OverallchartModule) },
{ path: 'Tablet', loadChildren: () => import('./Components/machine-lmw/machine-lmw.module').then(m => m.MachineLmwModule) },
{ path: 'reason_reg', loadChildren: () => import('./Components/reason-lmw/reason-lmw.module').then(m => m.ReasonLmwModule) },
{ path: 'oee_dashboard', loadChildren: () => import('./Components/oee-dashboard/oee-dashboard.module').then(m => m.OeeDashboardModule) },
{ path: 'reflect', loadChildren: () => import('./Components/reflect/reflect.module').then(m => m.ReflectModule) },
{ path: 'oee-report', loadChildren: () => import('./Components/idle-reason/idle-reason.module').then(m => m.IdleReasonModule) },
{ path: 'idle_report', loadChildren: () => import('./Components/idle-report/idle-report.module').then(m => m.IdleReportModule) },
{ path: 'overall_report', loadChildren: () => import('./Components/overall-report/overall-report.module').then(m => m.OverallreportModule) },
{ path: 'production', loadChildren: () => import('./Components/production/production.module').then(m => m.ProductionModule) },
{ path: 'report_idle', loadChildren: () => import('./Components/report-idle/report-idle.module').then(m => m.ReportIdleModule) },
{ path: 'oee', loadChildren: () => import('./Components/oee/oee.module').then(m => m.OeeModule) },
{ path: 'chart', loadChildren: () => import('./Components/chart/chart.module').then(m => m.ChartModule) },
{ path: 'andon-dashboard', loadChildren: () => import('./Components/andon-dashboard/andon-dashboard.module').then(m => m.AndonDashboardModule) },
{ path: 'main-dashboard', loadChildren: () => import('./Components/main-dashboard/main-dashboard.module').then(m => m.MainDashboardModule) },
{ path: 'sup-dashboard', loadChildren: () => import('./Components/sup-dashboard/sup-dashboard.module').then(m => m.SupDashboardModule) },
// { path: 'sdashboard', loadChildren: () => import('./Components/sdashboard/sdashboard.module').then(m => m.SdashboardModule) },
{ path: 'quality', loadChildren: () => import('./Components/quality/quality.module').then(m => m.QualityModule) },
{ path: 'mquality', loadChildren: () => import('./Components/mquality/mquality.module').then(m => m.MQualityModule) },

{ path: 'dashboardline', loadChildren: () => import('./Components/dashboardline/dashboardline.module').then(m => m.DashboardlineModule) },
{ path: 'component', loadChildren: () => import('./Components/component/component.module').then(m => m.ComponentModule) },
{ path: 'eff_report', loadChildren: () => import('./Components/eff-report/eff-report.module').then(m => m.EffReportModule) },
  
{ path: 'efficiency', loadChildren: () => import('./Components/efficiency/efficiency.module').then(m => m.EfficiencyModule) },
{ path: 'mefficiency', loadChildren: () => import('./Components/mefficiency/mefficiency.module').then(m => m.MEfficiencyModule) },
  
{ path: 'rabwin_dashboard', loadChildren: () => import('./Components/rabwin-dashboard/rabwin-dashboard.module').then(m => m.RabwinDashboardModule) },
  
{ path: 'tv', loadChildren: () => import('./Components/tv/tv.module').then(m => m.TvModule) },
  
{ path: 'color', loadChildren: () => import('./Components/color/color.module').then(m => m.ColorModule) },
  
// { path: 'kpi-dashboard', loadChildren: () => import('./Components/kpi-dashboard/kpi-dashboard.module').then(m => m.KpiDashboardModule) },
  
{ path: 'newdash', loadChildren: () => import('./Components/newdash/newdash.module').then(m => m.NewdashModule) },
  
{ path: 'cycle', loadChildren: () => import('./Components/cycle/cycle.module').then(m => m.CycleModule) },
{ path: 'newquality', loadChildren: () => import('./Components/newquality/newquality.module').then(m => m.NewQualityModule) },
{path:'loghistory',loadChildren: ()=>import('./Components/loghistory/loghistory.module').then(m=>m.LoghistoryModule),canActivate: [AuthGuard]},
{path:'logactivity',loadChildren: ()=>import('./Components/logdetails/logdetails.module').then(m=>m.LogdetailModule),canActivate: [AuthGuard]},
{path:'Spindle',loadChildren: ()=>import('./Components/spindle/spindle.module').then(m=>m.SpindleModule),canActivate: [AuthGuard]},
{ path: 'maintenancedb', loadChildren: () => import('./Components/maintenancedb/maintenacedb.module').then(m => m.MaintenancedbModule) },
{ path: 'Oeetv', loadChildren: () => import('./Components/oeetv/oeetv.module').then(m => m.OeetvModule) },
{ path: 'trendcharts', loadChildren: () => import('./Components/trendingchart/trending.module').then(m => m.TrendingChartModule) },
{ path: 'machine', loadChildren: () => import('./Components/maintenancedashboard/maintenancedashboard.module').then(m => m.MaintenancedashboardModule) },
{ path: 'Setting', loadChildren: () => import('./Components/settings/settings.module').then(m => m.SettingsModule) },
{ path: 'maintenance-report', loadChildren: () => import('./Components/maintenancereport/maintenancereport.module').then(m => m.MaintenancereportModule) },

//new pages for overall report
{ path: 'oeereport', loadChildren: () => import('./Components/idle-reasonnew/idle-reasonnew.module').then(m => m.IdleReasonnewModule) },
{ path: 'generalreport', loadChildren: () => import('./Components/reportnew/reportnew.module').then(m => m.ReportnewModule) },
{ path: 'Erp', loadChildren: () => import('./Components/erpexport/erpexport.module').then(m => m.ErpExportModule) },
{ path: 'cycle-start-to-start', loadChildren: () => import('./Components/cycle-start-to-start/cycle-start-to-start.module').then(m => m.CycleStartToStartModule) },

{ path: 'macroentryss', loadChildren: () => import('./Components/macro/macro.module').then(m => m.MacroModule) },
{ path: 'macroentry', loadChildren: () => import('./Components/macroentrys/macroentrys.module').then(m => m.MacroentrysModule) },
{ path: 'macromachine', loadChildren: () => import('./Components/macromachine/macromachine.module').then(m => m.MacroMachineModule) },

]
    
@NgModule({
imports: [RouterModule.forRoot(routes)],
exports: [RouterModule]
})
export class AppRoutingModule { }